// Dependencies
import { makeAsyncAPIRequest, tokenHeader } from '../helpers/api';
import { apiVersion } from '../config';
const resource = 'tracks';

async function getAll() {
	const method = 'get';
	const url = `/${apiVersion}/${resource}`;
	const headers = tokenHeader();
	return await makeAsyncAPIRequest({ method, url, headers });
}

export default {
	getAll,
};
