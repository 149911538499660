// Dependencies
import { observable, action, autorun } from 'mobx';

// Services
import { sessionService, tokenService } from '../services';

class SessionStore {
	@observable hasLoaded = false;
	@observable session = null;

	create = action(async (body) => {
		const { id, token } = await sessionService.create(body);
		tokenService.set(token);
		const data = await sessionService.get(id);
		this.session = data.session;
	});

	delete = action(async () => {
		await sessionService.delete();
		this.session = null;
		tokenService.delete();
	});

	load = action(async () => {
		if (process.browser) {
			try {
				const data = await sessionService.get();
				if (!data || !data.session) {
					await sessionService.delete();
					this.hasLoaded = true;
					this.session = null;
				} else {
					this.session = data.session;
					this.hasLoaded = true;
				}
			} catch (err) {
				this.hasLoaded = true;
				this.session = null;
				throw err;
			}
		} else {
			this.session = null;
		}
	});

	init = autorun(async () => {
		if (process.browser) {
			try {
				const data = await sessionService.get();
				if (!data || !data.session) {
					await sessionService.delete();
					this.hasLoaded = true;
					this.session = null;
				} else {
					this.session = data.session;
					this.hasLoaded = true;
				}
			} catch (err) {
				this.hasLoaded = true;
				this.session = null;
				throw err;
			}
		} else {
			this.session = null;
		}
	});
}

export default new SessionStore();
