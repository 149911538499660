// Dependencies
import { makeAsyncAPIRequest, tokenHeader } from '../helpers/api';
import { getItem, setItem, removeItem } from '../helpers/storage';
import tokenService from './tokenService';
import { apiVersion, sessionKey } from '../config';
const resource = 'sessions';

const sessionService = {
	/*
    Gets the session from the API via the browser cookie
  */
	get: async (sid) => {
		let id = getItem(sessionKey);
		if (!id && sid) {
			const maxAge = 1 * 60 * 60 * 24 * 30; // 30 days
			id = sid;
			setItem(sessionKey, id, { path: '/', maxAge });
		}
		const token = tokenService.get();
		if (!id || !token) {
			return null;
		}
		const method = 'get';
		const url = `/${apiVersion}/${resource}/${id}`;
		const headers = tokenHeader(token);
		const res = await makeAsyncAPIRequest({ method, url, headers });
		return res.data;
	},

	/*
    Creates a session via the API

    @params   data  Object    The session data
  */
	create: async (data) => {
		const method = 'post';
		const url = `/${apiVersion}/${resource}`;
		const res = await makeAsyncAPIRequest({ method, url, data });
		return res.data;
	},

	/*
    Deletes a session via the API

    @params   cb    Function  The function to execute once finished
  */
	delete: async () => {
		const sid = removeItem(sessionKey);
		const token = tokenService.delete();
		const method = 'delete';
		if (!sid) return null;
		const url = `/${apiVersion}/${resource}/${sid}`;
		const headers = tokenHeader(token);
		const res = await makeAsyncAPIRequest({ method, url, headers });
		return res.data;
	},
};

export default sessionService;
