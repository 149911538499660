// Dependencies
import { makeAsyncAPIRequest, tokenHeader } from '../helpers/api';
import { apiVersion } from '../config';
const resource = 'plan';

async function get() {
	const method = 'get';
	const url = `/${apiVersion}/${resource}`;
	const headers = tokenHeader();
	const { data } = await makeAsyncAPIRequest({ method, url, headers });
	return data;
}

export default {
	get,
};
